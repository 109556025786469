<template>
  <div class="content">
    <div v-if="loaded">
      <div class="headtitle">
        <div v-if="this.status == 'order'">
          <h1>ご注文を受付いたしました。</h1>
          <p class="notice">お支払いお手続きを期日までにお願い申し上げます。</p>
          <p class="notice2">
            <br />

            お客様ご登録のメールアドレスにご注文の完了をお知らせするメールを送信致しました。ご注文番号とお手紙番号などをご案内しておりますのでお手紙到着まで大切に保管ください。「ご注文ありがとうございます【サンタクロースからの手紙】」というタイトルでお送り致しております。<br />
            <br />
            なお、<strong
              >クレジットカード決済以外をご選択いただきましたお客様はご入金確認後に正式なお申し込みが完了となりますので、ご注文完了後なるべくお早めにお手続きをお願い申し上げます。</strong
            ><br />
            なお、お支払い方法につきましては、お客様にてご登録いただきましたメールアドレスに届く、ご注文確認に関するメール又はコンビニ各社の場合はお支払受付番号のご案内メール内に記載されておりますので、お支払い方法をご確認の上、お手続きをお願い申し上げます。<br />

            ご入金が完了いたしますと、クレジットカードは即時、コンビニ決済は数時間以内、銀行振込は１〜３営業日、郵便振替は３〜４営業日程度でご入金の確認が完了されます。

            <br />
            <br />ご入金の確認が完了いたしますと、
            「お支払いを確認いたしました【サンタクロースからの手紙】」というタイトルにて
            ご入金確認のメールが送信されますので、併せてご確認くださいませ。
          </p>

          <p
            class="payment_info"
            v-if="payment_info.message"
            v-html="payment_info.message"
          ></p>
        </div>
        <div v-if="this.status == 'complete'">
          <h1 v-if="!show_price">お申し込みが完了しました</h1>
          <h1 v-if="show_price">代金のお支払いが完了しました</h1>
          <p class="notice">
            ありがとうございました。お申し込みが完了しました！商品の発送までお待ちください。
          </p>
        </div>

        <div v-if="this.status == 'fail'">
          <h1>決済に失敗しました</h1>
          <p class="notice">お申し込みは一時キャンセルとなっています。</p>
        </div>
        <div v-if="this.status == 'delivered'">
          <h1>ご注文の商品を発送済みです</h1>
          <p class="notice">
            ありがとうございました。商品のご到着をお待ちください。
          </p>
        </div>
        <div v-if="this.status == 'half-delivered'">
          <h1>一部の商品を発送済みです</h1>
          <p class="notice">{{ statusmsg }}</p>
        </div>
      </div>
      <!--
<div v-if="!orderData.update_expire" class="order_updatebn"><router-link @click.native="scrollToTop" :to="'/mypage/update/'+orderData.ID">この注文の内容を変更する</router-link></div>
<div v-else class="order_updatebn disabled"><span>この注文の内容は変更できません</span></div>
-->

      <div class="itemcompute">
        <dl class="message" v-if="payment_info">
          <dt>ご注文番号</dt>
          <dd>{{ orderData.fields.orderno.value }}</dd>
        </dl>
        <dl class="message" v-if="payment_info">
          <dt>ご注文日時</dt>
          <dd>{{ orderData.page_newdate }}</dd>
        </dl>
        <dl class="message" v-if="payment_info">
          <dt>お支払い方法</dt>
          <dd>
            {{ orderData.fields.payment_type.textvalue }}
            <span v-if="payment_info.addfound">(追加決済あり)</span>
          </dd>
        </dl>

        <div v-if="show_price">
          <dl>
            <dt>商品金額合計</dt>
            <dd>
              {{ computed.price_taxation }}円<span class="tax">{{
                computed.taxview
              }}</span>
            </dd>
          </dl>
          <dl>
            <dt>合計配送料</dt>
            <dd>{{ computed.delivercost }}円</dd>
          </dl>
          <dl v-if="computed.payment_customdeliver != 0">
            <dt>カスタム配送料</dt>
            <dd>{{ computed.payment_customdeliver }}円</dd>
          </dl>
          <dl v-if="computed.payment_fee != 0">
            <dt>手数料</dt>
            <dd>{{ computed.payment_fee }}円</dd>
          </dl>

          <dl v-if="computed.campains && computed.campains.length">
            <dt>適用されたキャンペーン</dt>
            <dd v-for="(campain, c1) in computed.campains" :key="c1">
              <div class="camp_title">{{ campain.title }}</div>
              <!--<div class="camp_desc">{{ campain.description }}</div>-->
            </dd>
          </dl>

          <dl v-if="computed.discount != '0'">
            <dt>割引</dt>
            <dd>{{ computed.discount }}円</dd>
          </dl>
          <dl>
            <dt v-if="computed.taxtype == 'include'">(うち消費税)</dt>
            <dt v-if="computed.taxtype == 'exclude'">消費税</dt>
            <dd>{{ computed.tax }}円</dd>
          </dl>
          <dl>
            <dt>合計金額</dt>
            <dd>
              <strong
                >{{ computed.total_amount }}円<span class="tax">{{
                  computed.taxview
                }}</span></strong
              >
            </dd>
          </dl>
        </div>
      </div>

      <orderview :orderData="orderData" :options_prop="options"></orderview>
      <!--{{Object.keys(orderData)}}


<div class="bn_update"><button @click="bn_update">お申込内容を変更する</button></div>-->

      <router-link @click.native="scrollToTop" to="/">トップへ戻る</router-link>
    </div>
    <fullloader :loading="fullloading" />
  </div>
</template>

<script>
import orderview from "@/components/orderview.vue";
export default {
  components: { orderview },

  head: {
    title: {
      inner: "ご注文状況の確認",
    },
    // Meta tags
    meta: [
      { name: "robots", content: "none" },
      /*
      { name: 'description', content: 'A description of the page', id: 'desc' }, // id to replace intead of create element
      // ...
      // Twitter
      { name: 'twitter:title', content: 'Content Title' },
      // with shorthand
      { n: 'twitter:description', c: 'Content description less than 200 characters'},
      // ...
      // Google+ / Schema.org
      { itemprop: 'name', content: 'Content Title' },
      { itemprop: 'description', content: 'Content Title' },
      // ...
      // Facebook / Open Graph
      { property: 'fb:app_id', content: '123456789' },
      { property: 'og:title', content: 'Content Title' },
      // with shorthand
      { p: 'og:image', c: 'https://example.com/image.jpg' },
      // ...
      */
    ],
  },

  data: function () {
    return {
      orderData: "",
      computed: {},
      payment_info: {},
      imgviewflg: [],
      options: [],
      statusmsg: "",
      show_price: true,
    };
  },
  computed: {
    status: function () {
      //決済完了の場合は、発送完了を判別する
      if (this.orderData.fields.order_status.value == "complete") {
        //発送すべき数
        var maxpost = this.orderData.fields.mydeliver.subfields.length;
        var posted = 0;
        for (
          var i = 0;
          i < this.orderData.fields.mydeliver.subfields.length;
          i++
        ) {
          console.log(
            "ステータス",
            this.orderData.fields.mydeliver.subfields[i].status.value
          );
          if (
            this.orderData.fields.mydeliver.subfields[i].status.value ==
            "posted"
          ) {
            posted++;
          }
        }
        if (maxpost == posted) {
          return "delivered";
        } else if (posted) {
          this.statusmsg =
            maxpost +
            "つのうち、" +
            posted +
            "つの商品をすでに発送済みです。下部の各配送状況をご覧ください";
          return "half-delivered";
        }
      }
      return this.orderData.fields.order_status.value;
    },
  },
  created: function () {
    if (this.$route.params.label && this.$route.params.ordertoken) {
      window.location.href = "/status/" + this.$route.params.ordertoken;
    }
    console.log("status detail", this.$route.params);
    this.fullloading = true;
    this.$parent.middle = 2;
    this.loaded_countmax = 1;
    this.fetch("order_status", {
      ordertoken: this.$route.params.ordertoken,
    }).then(
      function (data) {
        this.fullloading = false;
        console.log("data", data);
        this.orderData = data;
        if (this.orderData.fields.prices.value) {
          this.computed = JSON.parse(this.orderData.fields.prices.value);
          if (this.computed.taxtype == "include")
            this.computed.taxview = "(税込)";
          if (this.computed.taxtype == "exclude")
            this.computed.taxview = "(税別)";
        }
        if (this.orderData.fields.payment_info.value) {
          let payment_info = JSON.parse(
            this.orderData.fields.payment_info.value
          );
          //支払情報配列より、直近の、追加決済など以外の通常決済を取得する。通常決済はflowが未定義又は空白

          for (let i = payment_info.length - 1; i >= 0; i--) {
            if (!!!payment_info[i].flow || i == 0) {
              this.payment_info = payment_info[i];
              break;
            }
          }

          let addfound = false;
          for (let i = payment_info.length - 1; i >= 0; i--) {
            if (payment_info[i].flow == "add") {
              addfound = true;
            }
          }
          this.payment_info.addfound = addfound;
        }
        this.options = this.orderData.fields.items.subfields[0].item.options;
        console.log("this.optionsを定義", this.options);
        this.loaded_countup();
      }.bind(this)
    );
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.headtitle {
  margin-top: 1.5em;
  p.notice {
    margin-bottom: 1.5em;
    line-height: 1.9;
    font-size: 0.9em;
  }
  p.notice2 {
    margin-bottom: 1.5em;
    line-height: 1.9;
    font-size: 0.75em;
    text-align: left;
  }
}
.content {
  color: #000;
}
.itemcompute {
}
.itemcompute dl {
  padding: 1em;
  display: flex;
  border-bottom: solid 1px #ccc;
}
.itemcompute dl dt {
  width: 15em;
}

.camp_title {
  font-size: 0.8em;
  color: #383838;
  font-weight: bold;
  padding: 0.7em;
  text-align: left;
}
.camp_desc {
  background: rgb(251, 251, 251);
  border-radius: 0.6em;
  padding: 0.7em;
  font-size: 0.7em;
  text-align: left;
}
.order_updatebn {
  margin: 1em 0;
  span {
    display: block;
    border: solid 1px rgb(207, 207, 207);
    padding: 0.7em 0;
    border-radius: 0.3em;
    font-size: 0.8em;
    text-decoration: none;
    color: rgb(153, 153, 153);
  }
  a {
    display: block;
    border: solid 1px rgb(72, 73, 146);
    padding: 0.7em 0;
    border-radius: 0.3em;
    text-decoration: none;
    &:hover {
      background: rgb(235, 235, 235);
    }
  }
}
</style>